import { GithubOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';
const Footer: React.FC = () => {
  const defaultMessage = 'Maner AI';
  const currentYear = new Date().getFullYear();
  return (
    <DefaultFooter
      copyright={`${currentYear} ${defaultMessage}`}
      links={
        [
          // {
          //   key: 'Maner AI',
          //   title: 'Maner AI',
          //   href: 'https://maner.ai',
          //   blankTarget: true,
          // },
        ]
      }
    />
  );
};
export default Footer;
