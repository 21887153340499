// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import RobotOutlined from '@ant-design/icons/es/icons/RobotOutlined';
import TableOutlined from '@ant-design/icons/es/icons/TableOutlined';
import ToolOutlined from '@ant-design/icons/es/icons/ToolOutlined';
import MessageOutlined from '@ant-design/icons/es/icons/MessageOutlined';
import SortAscendingOutlined from '@ant-design/icons/es/icons/SortAscendingOutlined';
import DeploymentUnitOutlined from '@ant-design/icons/es/icons/DeploymentUnitOutlined'
  export default {
    SmileOutlined,
UserOutlined,
RobotOutlined,
TableOutlined,
ToolOutlined,
MessageOutlined,
SortAscendingOutlined,
DeploymentUnitOutlined
  }