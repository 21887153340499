import { parse, stringify } from 'querystring';
import { ACCESS_TOKEN } from '@/constant';
import { history } from 'umi';
import { isEmpty } from 'lodash';

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const saveAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export const redirectLogin = () => {
  const { redirect } = getPageQuery(); // Note: There may be security issues, please note
  if (window.location.pathname !== '/login' && !redirect) {
    removeAccessToken();
    history.replace({
      pathname: '/login',
      search: stringify({
        redirect: window.location.href,
      }),
    });
  }
};

export const getAuthorization = () => {
  const token = getAccessToken();
  return isEmpty(token)
    ? {}
    : {
        Authorization: `Bearer ${token}`,
      };
};

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay || 0));
