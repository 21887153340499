export const ACCESS_TOKEN = 'access_token';

export const Models = [
  { label: 'AzureOpenAI gpt-3.5', value: 'azure-gpt-35-turbo' },
  { label: 'AzureOpenAI gpt-3.5-16k', value: 'azure-gpt-35-turbo-16k' },
  { label: 'AzureOpenAI gpt-4-8k', value: 'azure-gpt-4-8k' },
  { label: 'AzureOpenAI gpt-4-32k', value: 'azure-gpt-4-32k' },
  // { label: 'OpenAI gpt-3.5', value: 'gpt-3.5-turbo' },
  // { label: 'OpenAI gpt-3.5-16k', value: 'gpt-3.5-turbo-16k' },
  // { label: 'OpenAI gpt-4', value: 'gpt-4' },
  { label: 'MiniMax', value: 'minimax' },
  { label: '百度千帆 EB', value: 'bdqianfan-1' },
  { label: '百度千帆 EB-turbo', value: 'bdqianfan' },
  { label: '百度千帆 EB-4', value: 'bdqianfan-4' },
  { label: '智谱AI GLM-4', value: 'zhipuai-glm-4' },
  { label: '通义千问 turbo', value: 'qwen-turbo' },
  { label: '通义千问 max', value: 'qwen-max' },
  // { label: '百川 53B', value: 'Baichuan2-53B' },
  { label: 'claude-3-opus', value: 'claude-3-opus-20240229' },
];

export enum PromptType {
  '场景类' = '场景类',
  '语气风格类' = '语气风格类',
  '控制类' = '控制类',
  '其它' = '其它',
}

const gpt4Models = ['azure-gpt-4-8k', 'azure-gpt-4-32k'];
export const LoadModels = async (enableGpt4 = false) => {
  return Models.map((model) => {
    if (!enableGpt4 && gpt4Models.includes(model.value)) {
      return { ...model, disabled: true };
    }
    return model;
  });
  //return Models
};
