import { redirectLogin, getAuthorization, getAccessToken } from '@/utils';

import { extend, RequestOptionsInit, ResponseError, RequestResponse } from 'umi-request';
import { message, notification } from 'antd';

const errorHandler = async (error: ResponseError) => {
  const { response } = error;
  console.log('debug error', error);

  if (response && response.status) {
    const errorText = response.statusText;
    const { status, url } = response;
    const { code, msg } = await response.clone().json();
    if (code && msg) {
      message.destroy();
      message.error('报错: ' + msg);
    } else {
      notification.error({
        message: `请求错误 ${status}: ${url}`,
        description: errorText,
      });
    }
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }

  return response;
};

const request = extend({
  errorHandler,
  credentials: 'include', // 默认请求是否带上cookie
  timeout: 5000,
});

request.interceptors.response.use(async (response: Response, options: RequestOptionsInit) => {
  const { status } = await response.clone();
  if (status !== 200) {
    return response;
  }
  const { code, msg } = await response.clone().json();
  if (code === -10103) {
    redirectLogin();
    return;
  } else if (code > 0 && code !== 200) {
    message.destroy();
    message.error('报错: ' + msg);
    return;
  }
  return response;
});

request.interceptors.request.use((url: string, options: RequestOptionsInit) => {
  const token = getAccessToken();
  const headers = {
    ...(token ? { Authorization: 'Bearer ' + token } : {}),
    ...options.headers,
  };
  return {
    url,
    options: { ...options, headers },
  };
});

export default request;
